@font-face {
    font-family: 'Futura';
    src: url("../../assets/fonts/Futura.eot");
    src: local("☺"), 
    url("../../assets/fonts/Futura.woff") format("woff"), 
    url("../../assets/fonts/Futura.ttf") format("truetype"), 
    url("../../assets/fonts/Futura.svg") format("svg");
    font-weight: normal;
    font-style: normal; }
    
  * {
    font-family: 'Futura';
  }
.currency {
    padding-top: 40px;
    
}
.update{
    color: black;
    text-align: right;
    padding-top: 10px;
    /* font-size: 20px; */
}
.custom-row-class {
    color: white;
}
.table td{
    border: none;
}
.table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid transparent;
    border-top: 2px solid transparent;
    color: white;
    
}
.table thead  {
    box-shadow: 0px 15px 28px -12px black;  
}
.tablecurrency{
    width: 100%;
    font-size: 20px;
}
.rate_table_wrapper {
    /* -moz-box-shadow:    inset 0 -10px 10px -8px #000000;
    -webkit-box-shadow: inset 0 -10px 10px -8px #000000;
    box-shadow:         inset 0 -10px 10px -8px #000000; */
    padding-bottom: 10px;
    background-color: #f0f0f0;
}
.form-control {
    width: auto;
    float: left;
    margin-left: 20px;
    margin-bottom: 10px;
    border: 2px solid #ccc;
    border-radius: 4px;

    background-image: url('https://www.w3schools.com/css/searchicon.png');
    background-position: 10px 8px; 
    background-repeat: no-repeat;
    padding: 8px 20px 8px 40px;
}
.pictureformat{
    height: 50;
}
.price{
    background-color: #152247;
}
.price-header {
    text-align: left;
    font-size: 2.2vw;
    background-color: #152247;
    font-weight: bold;
}
.price-header-normal {
    text-align: left;
    font-size: 2.2vw;
    background-color: #152247;
    font-weight: bold;
}
.price-header-medie {
    display: none;
    text-align: left;
    background-color: #152247;
    font-weight: bold;
}
.search {
    text-align: left;
}
.img-printer{
    cursor: pointer;
}
.colunm-table-medie{
    display: none;
}
.buyingFormatter {
    font-size: 3vw;
}
.react-icons {
    vertical-align: middle;
    margin-left: 5px;
  }
.logoprint {
    display: none;
}
.flagimg {
    height: 50px;
}
@media only screen and (max-width: 991px) {
    .colunm-table-currency{
       display: none;
   }
   .colunm-table {
        display: none;
   }
   .price-header-normal {
        display: none;
   }
   .price-header-medie {
        display: block;
   }
   .colunm-table-medie{
        display: block;
    }
}
@media only screen and (min-width: 992px) {
    .colunm-table-currency{
        font-size: 22px;
    }
    .colunm-table {
        font-size: 22px;
    }
    .price-header-normal {
        font-size: 22px;
    }
    .price-header {
        font-size: 22px;
    }
    .custom-row-class div{
        font-size: 22px;
    }
    .buyingFormatter {
        font-size: 22px ;
    }
}
@media only screen and (max-width: 991px) {
    .colunm-table-currency{
        font-size: 21px;
    }
    .colunm-table {
        font-size: 21px;
    }
    .price-header-normal {
        font-size: 21px;
    }
    .price-header {
        font-size: 21px;
    }
    .custom-row-class div{
        font-size: 21px;
    }
    .buyingFormatter {
        font-size: 21px ;
    }
    .price-header-medie {
        font-size: 21px ;
   }

}
@media only screen and (max-width: 765px) {
    .colunm-table-currency{
        font-size: 20px;
    }
    .colunm-table {
        font-size: 20px;
    }
    .price-header-normal {
        font-size: 20px;
    }
    .price-header {
        font-size: 15px;
    }
    .custom-row-class div{
        font-size:13px !important;
    }
    .buyingFormatter {
        font-size: 15px;
    }
    .price-header-medie {
        font-size: 12px ;
   }
   .flagimg {
       height: 40px;
   }
}
@media print {
    /* html, body {
        height:100%; 
        margin: 0 !important; 
        padding: 0 !important;
        overflow: hidden;
      } */
    html, body {
        height:100%; 
        width: 100vh;
        /* overflow: visible !important; */
        /* float: none !important; */
        position: absolute;
        /* page-break-inside: auto; */
        zoom: 70%;
      }
      .table{
        position: absolute;
      }
      .header-update{
        color: black;
        text-align: right;
        padding-top: 10px;
        position: absolute;
        right: 0;
        /* font-size: 20px; */
    }
    .form-control {
        display: none;
    }
    .logoprint {
        display: block;
        height: 100px;
    }
    .colunm-table-currency{
        font-size: 20px;
    }
    .colunm-table {
        font-size: 20px;
        padding-top: 9px !important;
        padding-bottom: 9px !important;
        vertical-align: top;
    }
    .price-header-normal {
        font-size: 30px;
    }
    .price-header {
        font-size: 30px;
        
    }
    .custom-row-class div{
        font-size: 25px !important;
        padding-top: -5px;
        padding-bottom: -5px;
        
    }
    
    .buyingFormatter {
        font-size: 25px;
    }
    .price-header-medie {
        font-size: 20px ;
   }
   .flagimg {
       height: 40px;
   }
   .table td {
        padding-top: 9px !important;
        padding-bottom: 9px !important;
    }
  }
