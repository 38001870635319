.picture {
    width: 100%;
}
.address h1 {
    color: white;
    font-size: 48px;
    padding-top: 100px;
}
@media print {
    .address {
        display: none;
    }
  }

.resp-container {
    position: relative;
    overflow: hidden;
    padding-top: 67.75%;
}

.resp-iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
}