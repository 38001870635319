
@font-face {
  font-family: 'Futura';
  src: url("../../assets/fonts/Futura.eot");
  src: local("☺"), 
  url("../../assets/fonts/Futura.woff") format("woff"), 
  url("../../assets/fonts/Futura.ttf") format("truetype"), 
  url("../../assets/fonts/Futura.svg") format("svg");
  font-weight: normal;
  font-style: normal; }

* {
  font-family: 'Futura';
}
.outAboutus {
  background-image: url("http://siamexchange.co.th/images/bg-aboutus-mobile.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 100px;
}
.aboutus p{
  font-size: 16px;
}
.app-header.navbar {
  position: relative;
  -ms-flex-direction: row;
      flex-direction: row;
  height: 150px;
  padding: 0;
  margin: 0;
  background-color: transparent;
  border-bottom: 1px solid #a4b7c1; 
}

.main  {
  background-color: #152247;
}
.calpage{
  background-color: #152247;
}
.aboutus{
  color: #243470;
  padding-top: 25px;
}
.aboutus h1{
  font-size: 48px;
  margin-top: 50px;
}
.aboutus .pic{
  text-align: center
}
.contentabout{
  padding-top: 30px;
  font-size: 16px;
}
.contentabout p {
  font-family: 'Futura'
}
.pic {
  text-align: right;
}

.pic2 {
  margin: auto
}
.inapp{
  height: 50px;
}

@media only screen and (max-width: 991px) {
    .inapp{
  height: 35px;
}

}
@media print {
  .aboutus {
      display: none;
  }
}

aside {
  outline: none;
}