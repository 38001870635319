.banner {
    width: 100%;
    height: 500px;
}
.banner h3{
    color: white;
}
@media only screen and (max-width: 991px) {
    .banner {
        /* padding-top: 300px; */
        width: 100%;
        height: 350px;
    }
  }
@media only screen and (max-width: 767px) {
    .banner {
        /* padding-top: 300px; */
        width: 100%;
        height: 225px;
    }
}
@media print {
    .banner {
        display: none;
    }
  }
