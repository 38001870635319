 @font-face {
  font-family: 'Futura';
  src: url("../../assets/fonts/Futura.eot");
  src: local("☺"), 
  url("../../assets/fonts/Futura.woff") format("woff"), 
  url("../../assets/fonts/Futura.ttf") format("truetype"), 
  url("../../assets/fonts/Futura.svg") format("svg");
  font-weight: normal;
  font-style: normal; }
  
* {
  font-family: 'Futura';
}

.navigation a{
  font-family: 'Futura';
  font-size: 20px;
}
.profileMenu {
    width: 150px;
    right: 0;
  }
.header {
  height: 150px;
  z-index: 101;
  position: fix;
  top: 0px;
}
.nav-link a{
  cursor: pointer;
}

.logo {
  max-width: 150px;
  /* padding-left: 150px; */
  /* padding-top: 50px; */
}
.backHeader{
  opacity: 0.1;
  height: 150px;
  width: 100%;
  z-index: 101;
  color: red;
}
 * {  
	font-family: 'Futura', sans-serif;
	font-weight: 300;
	transition: all .1s ease; 
}

html, body {
		height: 100%;
}

h1 { font-size: 64px; }

.page-section {
		height: 480px;
		width: 50%;
		margin-left: 35%;
		margin-top: 5%;
		padding: 3em;
    background: linear-gradient(45deg, #43cea2 10%, #185a9d 90%);
		color: white;
		box-shadow: 0px 3px 10px 0px rgba(0,0,0,0.5);
}

.navigation {
  	position: fixed; 
    width: 100%;
    /* height: 150px; */
  	/* background-color: rgba(20, 34, 71, 0.7) ; */
    color: #fff;
    z-index: 1;

    background: linear-gradient(to bottom, rgba(20, 34, 71, 0.8) 69%, rgba(33, 50, 94, 0.4) 100%);
}

.navigation__link {
  display: block;
  color: #ddd; 
  text-decoration: none;
  padding: 1em;
  font-weight: 400;
}

.navigation__link :hover {
    background-color: #aaa;
}

.navigation__link .active {
color: white;
background-color: rgba(0,0,0,0.1);
} 

.list-lang {
  text-align: right;
  margin-right: 50px;
  /* margin-top: 10px; */
}
.menu {
  font-size: 19px;
  color: white;
  
}
.nav-item{
  padding-right: 20px;

}
.nav-item a{
  color: white !important;
}
.lang {
  background-color: #ffffff00;
  border: none;
  color: white;
  font-size: 18px;
}
@media only screen and (max-width: 991px) {
  .lang {
    font-size: 14px;
  }
}
.nav{
  margin-left: 50px;
}

/* ANIMATED MINUS */
.navbar-toggle.minus.collapsed .icon-bar:nth-of-type(1) {
	transform: translate3d(0,0,0) rotate(0deg);
}

.navbar-toggle.minus.collapsed .icon-bar:nth-of-type(2) {
	transform: translate3d(0,0,0) rotate(0deg);
}

.navbar-toggle.minus.collapsed .icon-bar:nth-of-type(3) {
	transform: translate3d(0,0,0) rotate(0deg);
}

.navbar-toggle.minus .icon-bar:nth-of-type(1) {
	transform: translate3d(0,6px,0);
}

.navbar-toggle.minus .icon-bar:nth-of-type(3) {
	transform: translate3d(0,-6px,0);
}

.navbar-toggle .icon-bar {
  background-color: #ffffff;
  display: block;
  width: 22px;
  height: 2px;
  border-radius: 1px;
}

.navbar-toggle .icon-bar+.icon-bar {
  margin-top: 4px;
}
/* END ANIMATED MINUS */
@media print {
  .list-lang {
      display: none;
  }
  .navbar-brand {
    display: block;
  }
}