@font-face {
    font-family: 'Futura';
    font-style: normal;
    font-weight: 400;
    src:
      local('FuturaNo2DEE'),
      /* local('FuturaNo2DEEMed'), */
      /* local('FuturaNo2SCDEEBoo'), */
      /* local('FuturaNo2SCDEEMed'), */
      url(../../assets/fonts/FuturaNo2DEE.ttf) format('ttf'),
      /* url(../../assets/fonts/FuturaNo2DEEMed.ttf) format('ttf'), */
      /* url(../../assets/fonts/FuturaNo2SCDEEBoo.ttf) format('ttf'), */
      /* url(../../assets/fonts/FuturaNo2SCDEEMed.ttf) format('ttf'); */
  }
  
.contact p,div{
    font-family: 'Futura';
  }
.contact div{
    color: white;
    font-size: 18px;
}
.contact{
    padding-top: 50px;
}
.tel{
    text-align: right;
}
.tel2{
    float: right;
}
@media print {
    .contact {
        display: none;
    }
}

.footer { 
    flex-direction: row;
    display: flex;

}

@media only screen and (max-width: 767.8px) {
    .footer { 
        flex-direction: column;
        display: flex;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991.8px) {
    .contact div{
        color: white;
        font-size: 13px;
    }
}

.contact-mail p {
    word-wrap: break-word;
}

